import { createSlice, current } from '@reduxjs/toolkit';

const filterSlice = createSlice({
    name: 'filter',
    initialState: {},
    reducers: {
        applyFilter: {
            reducer: (state, action) => {
                return { ...current(state), ...action.payload };
            },
            prepare: (value) => {
                return { payload: value };
            }
        }
    }
});

export const { applyFilter } = filterSlice.actions;
export default filterSlice.reducer;
