import { lazy, Suspense } from "react";
import Splashscreen from "./pages/Splashscreen";

const Routes = lazy(() => import("./Routes"));

const App = () => {
  return (
    <Suspense fallback={<Splashscreen />}>
      <Routes />
    </Suspense>
  );
};

export default App;
