import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    login: (state, action) => ({ ...action.payload, mode: "live" }),
    logout: (state, action) => {
      setTimeout(() => {
        window.location.replace(window.location.hostname.includes("algosuccess") ? "https://algosuccess.com" : "http://192.168.0.37:3001");
      }, 0);
      return null;
    },
    mode: (state, action) => {
      setTimeout(() => {
        window.location.replace(
          window.location.hostname.includes("algosuccess") ? "https://algosuccess.com/Dashboard" : "http://192.168.0.37:3001/Dashboard"
        );
      }, 0);
      return { ...state, mode: state?.mode === "live" ? "paper" : "live" };
    },
    setBrokerId: (state, action) => ({ ...state, ...action.payload }),
    paymentModal: (state, action) => ({ ...state, ...action.payload }),
    paymentModalFalse: (state, action) => ({ ...state, ...action.payload }),
    update: (state, action) => ({ ...state, ...action.payload })
  }
});

export const { login, logout, mode: changeMode, setBrokerId, paymentModal, paymentModalFalse, update } = slice.actions;
export default slice.reducer;
