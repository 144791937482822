import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as AlgoFavIcon } from "../files/icons/AlgoFavIcon.svg";

const Splashscreen = () => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ position: "absolute", background: "#010D2C", top: 0, bottom: 0, left: 0, right: 0 }}
        >
            <AlgoFavIcon />
        </Grid>
    );
};
export default Splashscreen;
